const en = {
  lng: "Français",
  home: "Home",
  logOut: "Log out",
  myNapaPrograms: "My NAPA Programs",

  "search...": "Search...",
  cantFindTheCustomer: "Can't find the customer?",
  searchForACustomer: "Search for a Customer",
  businessName: "Business Name",
  address: "Address",
  addressLine2: "Address Line 2",
  city: "City",
  province: "Province",
  postal: "Postal",

  helper_searchForACustomer: "Use the searchbox above to search for a customer",
  helper_searchForAnAsp:
    "Use the searchbox above to search for your Automotive Service Provider.",
  helper_searchForParts: "Use the searchbox above to add parts to your claim.",
  whatPartsWouldYouLikeToClaim: "What parts would you like to claim?",
  "qty:": "Qty:",
  partsRequiredForRebate: "Part(s) required for rebate",
  invoiceInformation: "Invoice Information",
  invoiceNumber: "Invoice Number",
  invoiceDate: "Invoice Date",

  promoCode: "Promo Code",
  myClaims: "My Claims",

  confirmationNumber: "Confirmation Number",
  automotiveServiceProvider: "Automotive Service Provider",
  vehicle: "Vehicle",
  status: "Status",
  submitOrTrackYourRebate: "Submit or track your rebate",
  emailOrCellPhone: "Email address or cell phone",
  next: "Next",
  beforeYouBegin: "Before you begin, you will need these:",
  originalServiceInvoiceCopy: "Original Service Invoice Copy",
  weSentASecurityCode: "We sent a security code to",
  promoCodeFromYourRebateClaimForm: "Promo code from your rebate claim form",

  error_phoneInUse:
    "Sorry, but this phone number is already in use. Try logging in.",
  error_emailInUse:
    "Sorry, but this email address is already in use. Try logging in.",
  thisTokenHasExpired: "This token has expired.",
  incorrectSecurityCodePleaseTryAgain:
    "Incorrect security code. Please try again.",
  thisTokenIsInvalid: "This token is invalid.",

  location: "Location",
  completeYourProfile: "Complete Your Profile",
  activePromotions: "Active Promotions",
  dashboard: "Dashboard",
  submissionDeadline: "Submission Deadline",
  description: "Description",
  submitAClaim: "Submit a Claim",
  requiredToQualify: "Required to Qualify",
  terms: "Terms",
  vehicleYear: "Year",
  vehicleInformation: "Vehicle Information",
  vehicleMake: "Make",
  vehicleMakeLoading: "Make loading...",
  useVehicleLookup: "Use vehicle lookup",
  vehicleMakeNotFound: "Vehicle make is not listed",
  vehicleModel: "Model",
  vehicleModelLoading: "Model loading...",
  vehicleModelNotFound: "Vehicle model is not listed",
  customerInformation: "Customer Information",
  licensePlate: "License Plate",
  "file.uploadHelper.uploadFiles": "Upload a copy of your invoice:",
  "file.uploadHelper.dragAndDrop": "drag and drop",
  "file.uploadHelper.or": "or",
  "file.uploadHelper.selectAFile": "select a file",
  "rebateAmount:": "Rebate Amount:",
  submitClaim: "Submit Claim",
  subjectToApproval: "* Subject to approval of claim.",
  invalidJwtToken: "Sorry, but your token is invalid",

  firstName: "First Name",
  lastName: "Last Name",
  phone: "Cell Phone",
  email: "Email",

  error_vehicleYear: "Please select a vehicle year.",
  error_vehicleMake: "Please select a vehicle make.",
  error_vehicleModel: "Please select a vehicle model.",
  error_invoiceNumber: "Please enter your invoice number.",
  error_invoiceDate: "Please select your invoice date.",
  error_firstName: "Please enter the customer's first name.",
  error_lastName: "Please enter the customer's last name.",
  error_email: "Please enter a valid email address.",
  error_phone: "Please enter a valid phone number.",
  error_address: "Please enter a valid street address.",
  error_city: "Please enter a valid city.",
  error_province: "Please select a province.",
  error_postal: "Please enter a valid postal code.",
  error_installerNotFound:
    "Please enter the business name of your automotive service provider.",
} as const;
type DefaultLanguage = typeof en;
export type Locale = Record<keyof DefaultLanguage, any>;
export default en;
