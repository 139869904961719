import { ReactNode, createContext, useContext, useState } from "react";
import { getSession } from "~/utils/sessions.server";
import en from "~/utils/locales/en";
import fr from "~/utils/locales/fr";

type BaseLanguage = typeof en;

type Languages = "en" | "fr";

type ContextDefaults = {
  t: <T extends keyof BaseLanguage>(key: T) => BaseLanguage[T];
  language: Languages;
  changeLanguage: (lng: Languages) => void;
};

export const LocalizationContext = createContext<ContextDefaults | null>(null);

export default function LocalizationProvider({
  children,
  lng,
}: {
  children: ReactNode;
  lng: Languages;
}) {
  const [state, setState] = useState<Languages>(lng || "en");

  function t<T extends keyof BaseLanguage>(key: T): BaseLanguage[T] {
    return translateBase(key, state);
  }

  return (
    <LocalizationContext.Provider
      value={{
        t,
        language: state,
        changeLanguage: (lng) => setState(lng),
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
}

export function useLocalization() {
  const currentLocalizationContext = useContext(LocalizationContext);
  if (!currentLocalizationContext) {
    throw new Error(
      "useLocalization has to be used within <CurrentUserContext.Provider>"
    );
  }
  return currentLocalizationContext;
}

export function useChangeLanguage() {
  const currentLocalizationContext = useContext(LocalizationContext);
  if (!currentLocalizationContext) {
    throw new Error(
      "useLocalization has to be used within <CurrentUserContext.Provider>"
    );
  }
  return currentLocalizationContext.changeLanguage;
}

export function translateBase<T extends keyof BaseLanguage>(
  key: T,
  lng: Languages
): BaseLanguage[T] {
  if (lng !== "en") {
    const returnString = fr[key];
    if (!returnString) return en[key];
    return returnString;
  }
  const returnString = en[key];
  return returnString;
}
